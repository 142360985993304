import { FlagIconCode } from 'react-flag-kit'
import { CountryEnum } from 'src/generated/graphql'

export interface CountryItemShape {
  label: string
  value: string
  flag: FlagIconCode
  countryCode: string
}

export const countries: CountryItemShape[] = [
  {
    label: 'armenia',
    value: CountryEnum.Armenia,
    flag: 'AM',
    countryCode: '374',
  },
  {
    label: 'austria',
    value: CountryEnum.Austria,
    flag: 'AT',
    countryCode: '43',
  },
  {
    label: 'azerbaijan',
    value: CountryEnum.Azerbaijan,
    flag: 'AZ',
    countryCode: '994',
  },
  {
    label: 'bahamas',
    value: CountryEnum.Bahamas,
    flag: 'BS',
    countryCode: '1-242',
  },
  {
    label: 'bahrain',
    value: CountryEnum.Bahrain,
    flag: 'BH',
    countryCode: '973',
  },
  {
    label: 'bangladesh',
    value: CountryEnum.Bangladesh,
    flag: 'BD',
    countryCode: '880',
  },
  {
    label: 'bermuda',
    value: CountryEnum.Bermuda,
    flag: 'BM',
    countryCode: '1-441',
  },
  {
    label: 'bulgaria',
    value: CountryEnum.Bulgaria,
    flag: 'BG',
    countryCode: '359',
  },
  { label: 'china', value: CountryEnum.China, flag: 'CN', countryCode: '86' },
  {
    label: 'czechia',
    value: CountryEnum.Czechia,
    flag: 'CZ',
    countryCode: '420',
  },
  {
    label: 'denmark',
    value: CountryEnum.Denmark,
    flag: 'DK',
    countryCode: '45',
  },
  {
    label: 'estonia',
    value: CountryEnum.Estonia,
    flag: 'EE',
    countryCode: '372',
  },
  {
    label: 'finland',
    value: CountryEnum.Finland,
    flag: 'FI',
    countryCode: '358',
  },
  {
    label: 'georgia',
    value: CountryEnum.Georgia,
    flag: 'GE',
    countryCode: '995',
  },
  {
    label: 'germany',
    value: CountryEnum.Germany,
    flag: 'DE',
    countryCode: '49',
  },
  { label: 'india', value: CountryEnum.India, flag: 'IN', countryCode: '91' },
  { label: 'iraq', value: CountryEnum.Iraq, flag: 'IQ', countryCode: '964' },

  {
    label: 'ireland',
    value: CountryEnum.Ireland,
    flag: 'IE',
    countryCode: '353',
  },
  {
    label: 'japan',
    value: CountryEnum.Japan,
    flag: 'JP',
    countryCode: '81',
  },
  {
    label: 'jordan',
    value: CountryEnum.Jordan,
    flag: 'JO',
    countryCode: '962',
  },
  {
    label: 'kazakhstan',
    value: CountryEnum.Kazakhstan,
    flag: 'KZ',
    countryCode: '7',
  },
  {
    label: 'kenya',
    value: CountryEnum.Kenya,
    flag: 'KE',
    countryCode: '254',
  },
  {
    label: 'kuwait',
    value: CountryEnum.Kuwait,
    flag: 'KW',
    countryCode: '965',
  },
  {
    label: 'kyrgyzstan',
    value: CountryEnum.Kyrgyzstan,
    flag: 'KG',
    countryCode: '996',
  },
  {
    label: 'latvia',
    value: CountryEnum.Latvia,
    flag: 'LV',
    countryCode: '371',
  },
  {
    label: 'lithuania',
    value: CountryEnum.Lithuania,
    flag: 'LT',
    countryCode: '370',
  },
  {
    label: 'moldova',
    value: CountryEnum.Moldova,
    flag: 'MD',
    countryCode: '373',
  },
  {
    label: 'nigeria',
    value: CountryEnum.Nigeria,
    flag: 'NG',
    countryCode: '234',
  },
  { label: 'norway', value: CountryEnum.Norway, flag: 'NO', countryCode: '47' },
  { label: 'oman', value: CountryEnum.Oman, flag: 'OM', countryCode: '968' },
  {
    label: 'pakistan',
    value: CountryEnum.Pakistan,
    flag: 'PK',
    countryCode: '92',
  },

  { label: 'poland', value: CountryEnum.Poland, flag: 'PL', countryCode: '48' },
  {
    label: 'puertoRico',
    value: CountryEnum.PuertoRico,
    flag: 'PR',
    countryCode: '1-787',
  },
  { label: 'qatar', value: CountryEnum.Qatar, flag: 'QA', countryCode: '974' },
  {
    label: 'romania',
    value: CountryEnum.Romania,
    flag: 'RO',
    countryCode: '40',
  },
  {
    label: 'saudiArabia',
    value: CountryEnum.SaudiArabia,
    flag: 'SA',
    countryCode: '966',
  },

  {
    label: 'slovakia',
    value: CountryEnum.Slovakia,
    flag: 'SK',
    countryCode: '421',
  },
  {
    label: 'southAfrica',
    value: CountryEnum.SouthAfrica,
    flag: 'ZA',
    countryCode: '27',
  },
  {
    label: 'stLucia',
    value: CountryEnum.StLucia,
    flag: 'LC',
    countryCode: '1-758',
  },
  { label: 'sweden', value: CountryEnum.Sweden, flag: 'SE', countryCode: '46' },
  { label: 'syria', value: CountryEnum.Syria, flag: 'SY', countryCode: '963' },
  {
    label: 'tajikistan',
    value: CountryEnum.Tajikistan,
    flag: 'TJ',
    countryCode: '992',
  },
  {
    label: 'ukraine',
    value: CountryEnum.Ukraine,
    flag: 'UA',
    countryCode: '380',
  },
  {
    label: 'usVirginIslands',
    value: CountryEnum.UsVirginIslands,
    flag: 'VI',
    countryCode: '1-340',
  },
  {
    label: 'unitedStates',
    value: CountryEnum.UnitedStates,
    flag: 'US',
    countryCode: '1',
  },
  {
    label: 'unitedArabEmirates',
    value: CountryEnum.UnitedArabEmirates,
    flag: 'AE',
    countryCode: '971',
  },
  {
    label: 'unitedKingdom',
    value: CountryEnum.UnitedKingdom,
    flag: 'GB',
    countryCode: '44',
  },
  {
    label: 'uzbekistan',
    value: CountryEnum.Uzbekistan,
    flag: 'UZ',
    countryCode: '998',
  },
  {
    label: 'zambia',
    value: CountryEnum.Zambia,
    flag: 'ZM',
    countryCode: '260',
  },
]

export default countries
